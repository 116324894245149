import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"

const FreiwilligenArbeit = ({data}) => {

  // Single Image Data
  const images = {
    image1: data.image1.childImageSharp.fluid,
    image2: data.image2.childImageSharp.fluid,
  }

  return (
    <Layout title="Freiwilligen Arbeit" slogan="Vielen Dank für Ihr Engagement" headerImage={data.headerImage.childImageSharp.fluid}>
      <div className="grid grid-2">
        <div>
          <p>
            <b>„Das beste Monument des Menschen ist der Mensch.“ </b> <br/>– Johann Wolfgang von Goethe <br/><br/>
            Die Freiwilligenmitarbeiter sind im Altersheim Bannau sehr geschätzt. Sie ermöglichen unseren Heimbewohnern Kontakt zur Dorfbevölkerung und zum Alltag. Beispielsweise, durch ihren Beitrag ist die Plauschgruppe entstanden, so können mehrmals im Jahr Ausflüge in die Nachbarschaft und in die heimliche Orte unternommen werden. Jede Woche bieten die Freiwilligen Spaziergänge oder kleine Ausflüge mit dem Auto ins Grüne an.
            Die Freiwilligen Helfer sind Personen, die sich freiwillig für das Wohl unserer Bewohner engagieren, dies ist eine ausschließlich ehrenamtliche Tätigkeit. 
          </p>
          <h3>Möchten Sie sich freiwillig betätigen?</h3>
          <p>
            Freiwilligen-Arbeit bedeutet nicht nur für unsere Bewohner mehr Lebensqualität, sondern auch für die Menschen, die sie ausüben, eine Bereicherung. Sie machen wertvolle Erfahrungen und knüpfen neue Beziehungen.
            Die Heimleitung Frau Beatrice Nufer ist Ansprechpartnerin für alle Fragen in Sachen Freiwilligenarbeit. Sie unterstützt Sie gerne beim Finden von geeigneten Einsatzmöglichkeiten.
            Bitte beachten: Persönliche Informationen, die den Heimbewohner oder andere Personen im Haus betreffen, müssen vertraulich behandelt werden und im Haus bleiben. Sollte ein freiwilliger Helfer nachweislich dagegen verstossen, entscheidet die Heimleitung über das Ausscheiden des freiwilligen Helfers von der Hilfe im Haus.
          </p>  
          <h5>Mögliche Freiwilligen-Arbeit:</h5>
          <ul>
            <li>Spazieren</li>
            <li>Plauschgruppe</li>
            <li>Unterstützung bei Veranstaltungen</li>
            <li>Besuchsdienst</li>
            <li>Fahrdienst</li>
            <li>und vieles mehr</li>
          </ul>

        </div>
        <div>
          <Image
            fluid={images.image2}
            style={{marginBottom: '1rem'}}
          />
          <Image
            fluid={images.image1}
            style={{marginBottom: '1rem'}}
          />
        </div>
      </div>
    </Layout>
  ) 
}

export default FreiwilligenArbeit

export const pageQuery = graphql`
  query {
    headerImage: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-misc-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "freiwilligen-arbeit-2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-freiwilligen-arbeit-2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`